<template>
  <v-container class="pt-4">
    <v-row>
      <v-col cols="12" md="12">
        <v-expansion-panels focusable flat>
          <v-expansion-panel>
            <v-expansion-panel-header>{{
              $t("degrees.searchFilters")
            }}</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="12" md="3" sm="12" class="mt-6">
                  <v-select
                    dense
                    :items="examTypeList"
                    :label="$t('degrees.examType')"
                    v-model="typeExam"
                    outlined
                  ></v-select>
                </v-col>
                <v-col cols="12" md="3" sm="12" class="mt-6">
                  <v-select
                    dense
                    :items="subjects"
                    item-text="subjectName"
                    item-value="subjectGuid"
                    v-model="subjectGuidValue"
                    :label="$t('degrees.subjects')"
                    outlined
                  >
                    <v-list-item
                      slot="prepend-item"
                      @click="subjectGuidValue = null"
                    >
                      <v-list-item-title> {{ $t("all") }} </v-list-item-title>
                    </v-list-item>
                  </v-select>
                </v-col>
                <v-col cols="12" md="3" sm="12" class="mt-6">
                  <v-select
                    dense
                    :items="LecTech"
                    v-model="depValue"
                    item-text="departmentName"
                    item-value="departmentName"
                    :label="$t('degrees.department')"
                    outlined
                  >
                    <v-list-item slot="prepend-item" @click="depValue = null">
                      <v-list-item-title> {{ $t("all") }} </v-list-item-title>
                    </v-list-item>
                  </v-select>
                </v-col>
                <v-col cols="12" md="3" sm="12" class="mt-6">
                  <v-select
                    dense
                    :items="LecTech"
                    :label="$t('degrees.stage')"
                    v-model="stageValue"
                    item-text="stageName"
                    item-value="stageName"
                    outlined
                  >
                    <v-list-item slot="prepend-item" @click="stageValue = null">
                      <v-list-item-title> {{ $t("all") }} </v-list-item-title>
                    </v-list-item>
                  </v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12" sm="12">
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    :label="$t('writeWhatYouSearch')"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-row class="py-4">
      <v-col
        cols="12"
        lg="3"
        md="4"
        sm="12"
        v-for="(item, index) in visiblePages"
        :key="index"
        :search="search"
      >
        <v-card flat class="pa-4">
          <v-row class="ma-0 pa-0">
            <v-col cols="12" md="6" sm="12" class="ma-0 pa-0">
              <div
                class="rounded-sm rounded-bl-xl pos pa-2 primary white--text"
              >
                {{ item.examDate.substr(0, 10) }}
              </div>
            </v-col>
            <v-spacer></v-spacer>
            <v-menu>
              <template v-slot:activator="{ on: menu, attrs }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on: tooltip }">
                    <v-btn
                      icon
                      color="grey"
                      class="mt-n3 mx-n3 zin pos2"
                      v-bind="attrs"
                      v-on="{ ...tooltip, ...menu }"
                    >
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("more") }}</span>
                </v-tooltip>
              </template>
              <v-list>
                <v-list-item @click="deleteDegree(item)">
                  <v-list-item-title>{{ $t("delete") }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-row>
          <v-row class="text-center py-4">
            <v-col class="mt-8">
              <v-badge
               class="degree-badge"
                :color="item.isDone ? 'green' : 'orange'"
                overlap
                :icon="item.isDone ? 'mdi-check' : 'mdi-alert'"

              >
                <img
                  src="../../assets/quiz.svg"
                  width="100px"
                  v-if="item.examType == 0"
                />
                <img
                  src="../../assets/mid.svg"
                  width="100px"
                  v-else-if="item.examType == 1"
                />
                <img src="../../assets/final.svg" width="100px" v-else />
              </v-badge>
              <!-- {{`../../assets/fileType/${item.filetype}.svg`}} -->
            </v-col>
          </v-row>
          <v-row class="">
            <v-col>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="mb-1">
                    {{ item.subjectName }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="pb-3">{{
                    ` ${item.examMonth} ( ${item.examName} ) `
                  }}</v-list-item-subtitle>
                  <v-list-item-subtitle>{{
                    item.departmentsName
                  }}</v-list-item-subtitle>
                  <v-list-item-subtitle>{{
                    `${$t("lecOne.section")} ${item.stageName} - ${
                      item.studyName
                    } - ${item.sectionName}`
                  }}</v-list-item-subtitle>
                  <v-list-item-subtitle>{{
                    `${$t("degrees.TheNumberOfStudents")} : ${
                      item.studentCount
                    }`
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-btn outlined @click="openDegree(item)" class="teal" block dark
              >{{ $t("Preview") }}
              <v-icon class="px-2">mdi-book-account-outline</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
        <v-col cols="12" md="12">
              <v-pagination
                v-if="degrees[0]"
                v-model="page"
                :length="Math.ceil(degreesFilter.length / perPage)"
                total-visible="15"

              ></v-pagination>
            </v-col>
    </v-row>
    <v-row v-if="!degreesFilter[0]">
      <v-col cols="12" md="12" class="text-center mt-10">
        <img src="../../assets/folder.svg" width="150px" />
        <h3>{{ $t("degrees.ThereIsNoDegreeList") }}</h3>
      </v-col>
    </v-row>

    <v-fab-transition v-if="isInRole(2)">
      <v-btn
        color="secondary"
        fab
        large
        elevation="6"
        fixed
        bottom
        :left="$vuetify.rtl"
        :right="!$vuetify.rtl"
        class="v-btn--example"
        :class="$store.state.drawerMessage ? 'ml-16' : ''"
                :style="!$vuetify.rtl?'right:80px':''"
        @click="openDia"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>
    <v-dialog
      transition="dialog-bottom-transition"
      persistent
      max-width="600"
      v-model="dialog"
    >
      <v-card>
        <v-toolbar color="primary darken-1" dark>{{
          $t("degrees.AddAListOfDegrees")
        }}</v-toolbar>
        <v-card-text class="mt-4">
          <!-- <v-autocomplete
            v-model="sectionGuid"
            :items="departmentList"
            outlined
            chips
            small-chips
            :label="$t('lecOne.selectStage')"
            item-text="name"
            item-value="sectionGuid"
          ></v-autocomplete> -->
          <v-autocomplete
            v-model="subjectGuid"
            :items="subjects"
            outlined
            chips
            small-chips
            :label="$t('degrees.selectSubject')"
            item-text="subjectName"
            item-value="subjectGuid"
            @change="clearSelect"
          ></v-autocomplete>

          <v-autocomplete
            v-model="deparmentSelect"
            :items="departmentList"
            outlined
            :label="$t('home.departmentChoese')"
            item-text="departmentName"
            item-value="departmentGuid"
            @change="clearSelect2"
          >
          </v-autocomplete>

          <v-autocomplete
            v-model="stageSelect"
            :items="stageList"
            outlined
            :label="$t('lecOne.selectStage')"
            :item-text="getFieldText"
            item-value="stageGuid"
            @change="clearSelect3"
          >
          </v-autocomplete>

          <v-autocomplete
            v-model="sectionGuid"
            :items="sectionList"
            outlined
            :label="$t('mail.selectSection')"
            item-text="sectionName"
            item-value="sectionGuid"
          ></v-autocomplete>
          <v-text-field
            :label="$t('degrees.month')"
            :placeholder="$t('degrees.WriteTheMonthOfTheExam')"
            outlined
            v-model="monthName"
          ></v-text-field>

             <span style="font-size:12px">{{ $t("date") }} </span>
            <DxDateBox
              display-format="yyyy/MM/dd"
              :placeholder="$t('date')"
              styling-mode="outlined"
              v-model="date"
              :value="date"
              type="date"
              :use-mask-behavior="true"
            />
            <div class="v-text-field__details">
              <div class="v-messages theme--light"><div class="v-messages__wrapper"></div></div>
            </div>

          <!-- <v-menu
            v-model="menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                outlined
                v-model="date"
                :label="$t('date')"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>

            <v-date-picker v-model="date" @input="menu = false"></v-date-picker>
          </v-menu>
           -->
          <v-select
            :items="examTypeList2"
            v-model="examTypeSelect"
            :label="$t('degrees.ChooseTheTypeofExam')"
            outlined
          ></v-select>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-spacer></v-spacer>
          <v-btn color="error darken-1" text @click="dialog = false">
            {{ $t("close") }}
          </v-btn>
          <v-btn
            color="primary darken-1"
            @click="addDegree()"
            :loading="addLoad"
          >
            {{ $t("save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import DxDateBox from "devextreme-vue/date-box";

export default {
    components: {
    DxDateBox
  },
  data() {
    return {
      stageSelect: null,
      deparmentSelect: null,
      typeExam: "",
      addLoad: false,
      monthName: null,
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      examTypeList: [
        { text: this.$t("all"), value: "" },
        { text: this.$t("degrees.daily"), value: 0 },
        { text: this.$t("degrees.Monthly"), value: 1 },
        { text: this.$t("degrees.midFinal"), value: 2 },
        { text: this.$t("degrees.final"), value: 3 },
      ],
      examTypeList2: [
        { text: this.$t("degrees.daily"), value: 0 },
        { text: this.$t("degrees.Monthly"), value: 1 },
        { text: this.$t("degrees.midFinal"), value: 2 },
        { text: this.$t("degrees.final"), value: 3 },
      ],
      examTypeSelect: null,
      search: "",
      items: ["sdf"],
      degrees: [],
      overlay: false,
      dialog: false,
      stages: [],
      sectionGuid: null,
      subjectGuid: null,
      LecTech: [],
      subjects: [],
      dateTime: null,
      subjectGuidValue: null,
      depValue: null,
      stageValue: null,
      page: 1,
      perPage: 8,
    };
  },
  computed: {
      visiblePages() {
      return this.degreesFilter.slice(
        (this.page - 1) * this.perPage,
        this.page * this.perPage
      );
    },


    degreesFilter() {
      let dataList = this.degrees;

      // if (
      //   !this.stageValue &&
      //   !this.depValue &&
      //   this.typeExam === "" &&
      //   !this.search &&
      //   !this.subjectGuidValue
      // ) {
      //   return this.degrees;
      // }
      if (this.stageValue) {
        dataList = dataList.filter(
          (item) => item.stageName === this.stageValue
        );
      }
      if (this.depValue) {
        dataList = dataList.filter(
          (item) => item.departmentsName === this.depValue
        );
      }
      if (this.subjectGuidValue) {
        dataList = dataList.filter(
          (item) => item.subjectGuid === this.subjectGuidValue
        );
      }
      if (this.typeExam !== "") {
        const { typeExam } = this;
        dataList = dataList.filter((item) => item.examType === typeExam);
      }
      if (this.search) {
        dataList = dataList.filter((item) => this.search
            .toLowerCase()
            .split(" ")
            .every(
              (v) => item.subjectName.toLowerCase().includes(v) ||
                item.examMonth.toLowerCase().includes(v)
            ));
      }

      return dataList;
    },
    departmentList() {
      let dataList = [];
      if (this.subjectGuid) {
        dataList = this.subjects.filter(
          (item) => item.subjectGuid === this.subjectGuid
        );
        // console.log(dataList);
        return dataList[0].departments;
      }
      return dataList;
    },
    stageList() {
      let dataList = [];
      if (this.deparmentSelect) {
        dataList = this.departmentList.filter(
          (item) => item.departmentGuid === this.deparmentSelect
        );
        return dataList[0].stages;
      }
      return dataList;
    },
    sectionList() {
      let dataList = [];
      if (this.stageSelect) {
        dataList = this.stageList.filter(
          (item) => item.stageGuid === this.stageSelect
        );
        return dataList[0].sections;
      }
      return dataList;
    },
  },
  methods: {
    getFieldText(item) {
      return `${item.stageName} - ${item.studyName}`;
    },
    clearSelect() {
      this.sectionGuid = null;
      this.stageSelect = null;
      this.deparmentSelect = null;
    },
    clearSelect2() {
      this.sectionGuid = null;
      this.stageSelect = null;
    },
    clearSelect3() {
      this.sectionGuid = null;
    },
    openDia() {
      this.clear();
      this.dialog = !this.dialog;
    },
    clear() {
      this.stageSelect = null;
      this.deparmentSelect = null;
      this.subjectGuid = null;
      this.sectionGuid = null;
      this.monthName = null;
      this.examTypeSelect - null;
    },
    addDegree() {
      if (this.sectionGuid && this.subjectGuid && this.monthName && this.date) {
        this.addLoad = true;
        const data = {
          subjectGuid: this.subjectGuid,
          sectionGuid: this.sectionGuid,
          examMonth: this.monthName,
          examDate: `${this.date} ${this.dateTime}`,
          examType: this.examTypeSelect,
        };
        // console.log(data);
        this.axios
          .post("Degrees/AddDegrees", data)
          .then((res) => {
            this.dialog = false;
            // this.getDegrees();
            this.$swal.fire({
              title: this.$t("operationAccomplishedSuccessfully"),
              text: "",
              icon: "success",
              confirmButtonText: this.$t("close"),
              toast: true,
              position: "bottom-start",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
              },
            });

            this.$router.push({
              path: `degree/${res.data.data.sessionGuid}/${res.data.data.sectionGuid}`,
              params: {
                id: res.data.data.sessionGuid,
                id2: res.data.data.sectionGuid,
              },
            });
          })
          .catch(() => {
            this.$swal.fire({
              title: this.$t("AnErrorOccurredDuringTheProcess"),
              text: "",
              icon: "error",
              confirmButtonText: this.$t("close"),
            });
          })
          .finally(() => {
            this.addLoad = false;
          });
      } else {
        this.$swal.fire({
          title: this.$t("BeSureToEnterTheInformation"),
          text: "",
          icon: "error",
          confirmButtonText: this.$t("close"),
        });
      }
    },
    getLecTech() {
      this.overlay = true;
      this.axios
        .get("Subjects/Get")
        .then((res) => {
          this.LecTech = res.data.data.sections;
          this.subjects = res.data.data.subjects;
          for (let i = 0; i < this.LecTech.length; i++) {
            this.LecTech[i].name =
              `${this.LecTech[i].departmentName
              } / ${
              this.LecTech[i].stageName
              } / ${
              this.LecTech[i].sectionName
              } / ${
              this.LecTech[i].studyName}`;
          }
          // console.log(this.LecTech);
        })
        .catch((err) => {})
        .finally(() => {
          this.overlay = false;
        });
    },
    deleteDegree(item) {
      this.$swal
        .fire({
          title: this.$t("AreYouSureAboutTheOperation"),
          text: "",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: this.$t("yes"),
          cancelButtonText: this.$t("no"),
        })
        .then((result) => {
          if (result.value) {
            this.overlay = true;
            this.axios
              .post("Degrees/DeleteDegrees", { sessionGuid: item.sessionGuid })
              .then((res) => {
                this.getDegrees();
                this.$swal.fire({
                  title: this.$t("operationAccomplishedSuccessfully"),
                  text: "",
                  icon: "success",
                  confirmButtonText: this.$t("close"),
                  toast: true,
                  position: "bottom-start",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                  didOpen: (toast) => {
                    toast.addEventListener("mouseenter", this.$swal.stopTimer);
                    toast.addEventListener(
                      "mouseleave",
                      this.$swal.resumeTimer
                    );
                  },
                });
              })
              .catch(() => {
                this.$swal.fire({
                  title: this.$t("AnErrorOccurredDuringTheProcess"),
                  text: "",
                  icon: "error",
                  confirmButtonText: this.$t("close"),
                });
              })
              .finally(() => {
                this.overlay = false;
              });
          }
        });
    },
    editDegree(item) {},
    openDegree(id) {
      // console.log(id);
      this.$router.push({
        path: `degree/${id.sessionGuid}/${id.sectionGuid}`,
        params: { id: id.sessionGuid, id2: id.sectionGuid },
      });
    },
    getDegrees() {
      this.overlay = true;
      this.axios
        .get("Degrees/GetCards")
        .then((res) => {
          this.degrees = res.data.data;
          for (let i = 0; i < this.degrees.length; i++) {
            if (this.degrees[i].examType == 0) {
              this.degrees[i].examName = this.$t("degrees.daily");
            } else if (this.degrees[i].examType == 1) {
              this.degrees[i].examName = this.$t("degrees.Monthly");
            } else if (this.degrees[i].examType == 2) {
              this.degrees[i].examName = this.$t("degrees.midFinal");
            } else {
              this.degrees[i].examName = this.$t("degrees.final");
            }
          }
          // console.log(this.degrees);
        })
        .catch((err) => {})
        .finally(() => {
          this.overlay = false;
        });
    },
  },
  created() {
    const today = new Date();
    const time =
      `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`;
    this.dateTime = time;
    // console.log(this.dateTime);
    this.getDegrees();
    this.getLecTech();
  },
};
</script>

<style lang="scss" scope >
.pos {
  position: absolute;
  top: 0;
  right: 0;
}
.pos2 {
  position: absolute;
  left: 10px;
}
.zin {
  z-index: 1;
}

.degree-badge .v-badge__badge {
    border-radius: 20px !important;
    height: 40px !important;
    line-height: 2.3 !important;
    min-width: 40px !important;

}
.degree-badge .v-badge__badge .v-icon{
  font-size: 20px !important;

}


</style>
